<template>
  <div class="credit-card">
    <div class="wrapper">
      <div class="title font-md">
        <span>결제 카드 정보</span>
      </div>
      <div class="desc font-sm">카드 정보 입력 후 {{ modalParams.temp ? "입력하기" : "저장하기" }} 버튼을 눌러주세요.</div>
      <div class="form">
        <div class="form-group">
          <label :for="`${component.name}CardNumber1`" class="font-sm">카드 번호</label>
          <div class="wrapper">
            <div class="inner">
              <div class="td quarter">
                <Number :id="`${component.name}CardNumber1`" :value.sync="state.form.cardNumbers[0]" :allowZero="true" :noComma="true" :maxlength="4" :keyup="keyup" :enter="save"/>
              </div>
              <div class="td quarter">
                <Number :id="`${component.name}CardNumber2`" :value.sync="state.form.cardNumbers[1]" :allowZero="true" :noComma="true" :maxlength="4" :keyup="keyup" :enter="save"/>
              </div>
              <div class="td quarter">
                <Number :id="`${component.name}CardNumber3`" :value.sync="state.form.cardNumbers[2]" :allowZero="true" :noComma="true" :maxlength="4" :keyup="keyup" type="password" :enter="save"/>
              </div>
              <div class="td quarter">
                <Number :id="`${component.name}CardNumber4`" :value.sync="state.form.cardNumbers[3]" :allowZero="true" :noComma="true" :maxlength="4" :keyup="keyup" :enter="save"/>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label :for="`${component.name}ExpireMonth`" class="font-sm">유효 기간</label>
          <div class="wrapper">
            <div class="inner">
              <div class="td half">
                <select :id="`${component.name}ExpireMonth`" class="form-control border-focus-point font-sm" v-model="state.form.expireMonth">
                  <option value="">월</option>
                  <option :value="n < 10 ? '0' + n : n.toString()" v-for="(n, idx) in 12" :key="idx">{{ n }}월</option>
                </select>
              </div>
              <div class="td half">
                <select :id="`${component.name}ExpireYear`" class="form-control border-focus-point font-sm" v-model="state.form.expireYear">
                  <option value="">년</option>
                  <option :value="new Date().getFullYear() + idx" v-for="(n, idx) in 10" :key="idx">{{ new Date().getFullYear() + idx }}년</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label :for="`${component.name}Birth`" class="font-sm">
            <span>생년월일 </span>
            <span class="font-xs">(6자리, 법인카드는 사업자등록번호 10자리)</span>
          </label>
          <Number :id="`${component.name}Birth`" :value.sync="state.form.birth" :allowZero="true" :noComma="true" :maxlength="10" placeholder="ex) 950405" :enter="save" autocomplete="off"/>
        </div>
        <div class="form-group">
          <label :for="`${component.name}CardPassword`" class="font-sm">
            <span>비밀번호 </span>
            <span class="font-xs">(앞 2자리)</span>
          </label>
          <div class="wrapper">
            <div class="inner">
              <div class="td half">
                <Number :id="`${component.name}CardPassword`" type="password" :value.sync="state.form.cardPassword" :allowZero="true" :noComma="true" :maxlength="2" :enter="save"/>
              </div>
              <div class="td half">
                <input type="password" class="form-control border-focus-point font-sm" value="**" disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action">
      <button :id="`${component.name}Submit`" class="btn btn-point btn-block font-sm" @click="save()">{{ modalParams.temp ? "입력하기" : "저장하기" }}</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCreditCard";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xs"});
    });

    const state = reactive({
      form: {
        birth: "",
        cardNumber: "",
        cardNumbers: ["", "", "", ""],
        cardPassword: "",
        expireMonth: "",
        expireYear: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const keyup = (e) => {
      if (e.which >= 48 && e.which <= 57 || e.which >= 96 && e.which <= 105) {
        if (e.target.id.startsWith(`${component.name}CardNumber`) && e.target.value?.length === 4) {
          if (e.target.id === `${component.name}CardNumber4`) {
            document.getElementById(`${component.name}ExpireMonth`).focus();
          } else {
            const childNodes = e.target.parentNode?.nextSibling?.childNodes;

            if (childNodes?.length) {
              childNodes[0].select();
            }
          }
        }
      }
    };

    const save = () => {
      const request = () => {
        const args = lib.getRenewed(state.form);
        delete args.cardNumbers;

        http.post("/api/payments", args).then(({data}) => {
          store.commit("closeModal", {
            name: component.name,
            onClose(modal) {
              store.dispatch("callback", {modal});
              store.commit("setSwingMessage", data.message);
            }
          });
        }).catch(httpError((err) => {
          store.commit("setSwingMessage", err.response.data.message);
        }));
      };

      for (let i in state.form.cardNumbers) {
        const id = `${component.name}CardNumber${window.Number(i) + 1}`;

        if (!state.form.cardNumbers[i]) {
          document.getElementById(id).focus();
          return store.commit("setSwingMessage", "카드 번호를 입력해주세요.");
        } else if (state.form.cardNumbers[i]?.length !== 4 || !lib.isNumeric(state.form.cardNumbers[i])) {
          document.getElementById(id).focus();
          return store.commit("setSwingMessage", "카드 번호 값을 확인해주세요.");
        }
      }

      if (!state.form.expireMonth) {
        document.getElementById(`${component.name}ExpireMonth`).focus();
        return store.commit("setSwingMessage", "유효 기간을 입력해주세요.");
      } else if (!state.form.expireYear) {
        document.getElementById(`${component.name}ExpireYear`).focus();
        return store.commit("setSwingMessage", "유효 기간을 입력해주세요.");
      } else if (!state.form.birth) {
        document.getElementById(`${component.name}Birth`).focus();
        return store.commit("setSwingMessage", "생년월일(혹은 사업자등록번호) 값을 입력해주세요.");
      } else if ((state.form.birth.length !== 6 && state.form.birth.length !== 10) || !lib.isNumeric(state.form.birth)) {
        document.getElementById(`${component.name}Birth`).focus();
        return store.commit("setSwingMessage", "생년월일(혹은 사업자등록번호) 값을 확인해주세요.");
      } else if (state.form.cardPassword.length !== 2 || !lib.isNumeric(state.form.cardPassword)) {
        document.getElementById(`${component.name}CardPassword`).focus();
        return store.commit("setSwingMessage", "비밀번호 2자리를 입력해주세요.");
      }

      state.form.cardNumber = state.form.cardNumbers.join("");

      if (modalParams.temp) {
        store.commit("confirm", {
          message: "간편 결제 카드로 등록하시겠습니까?",
          allow: request,
          disallow() {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {
                  modal,
                  params: state.form
                });
              }
            });
          }
        });
      } else {
        request();
      }
    };

    onMounted(() => {
      document.getElementById(`${component.name}CardNumber1`)?.focus();
    });

    return {component, state, modalParams, keyup, save};
  }
});
</script>

<style lang="scss" scoped>
.credit-card {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      .form-group {
        margin-bottom: $px20;

        input[type=text], input[type=tel], input[type=password], select {
          height: $formHeight;
          width: 100%;
        }

        > .wrapper {
          margin: 0 $px-5;

          > .inner {
            display: table;
            table-layout: fixed;
            width: 100%;

            .td {
              display: table-cell;
              padding: 0 $px5;

              &.quarter {
                width: 25%;
              }

              &.half {
                width: 50%;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>